// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { addToUserList, removeFromUserList,
         toggleShowSide1, 
         setSpeakerState, setCardSide } from './actions';

import { StarIcon, SpeakerXMarkIcon,EyeSlashIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarFillIcon, SpeakerWaveIcon, EyeIcon} from '@heroicons/react/24/solid'; 

export default function Card({ card }: any, isPlaying: boolean) {

  const dispatch = useDispatch();
  const [isAdded, setIsAdded] = useState(false);
  const [voicesLoaded, setVoicesLoaded] = useState(false);
  const [allVoices, setAllVoices] = useState<SpeechSynthesisVoice[]>([]);  // Explicitly type as SpeechSynthesisVoice[]

  const userList = useSelector((state: any) => state.app.userList);
  const speaker = useSelector((state: any) => state.app.speakerState) ?? true;
  let side = useSelector((state: any) => state.app.cardSide) ?? false;
  let showSide1 = useSelector((state: any) => state.app.cardsVisibility) ?? true;

  const prevSide1 = useRef(card.fields.side1);
  const currentAudioRef = useRef<HTMLAudioElement | null>(null);
  const audioCurrentTimeRef = useRef<number>(0);  // Track the current time using useRef

  useEffect(() => {
    if (prevSide1.current !== card.fields.side1) {
      // Trigger the transition when side1 changes
      prevSide1.current = card.fields.side1;
    }
  }, [card.fields.side1]);


  useEffect(() => {
    // Effect cleanup: If the component unmounts, stop any playing audio
    return () => {
      if (currentAudioRef.current) {
        currentAudioRef.current.pause();
        currentAudioRef.current.currentTime = 0;
      }
    };
  }, []);  // Empty dependency array to ensure this only runs on mount/unmount


  useEffect(() => {
    // Check if the card is in the userList and update isAdded accordingly
    const cardIsAdded = userList.some((userCard: any) => userCard.fields.side1 === card.fields.side1);
    setIsAdded(cardIsAdded);
  }, [userList, card]);

  const side1Length = card.fields.side1.length;
  const side2Length = card.fields.side2.length;
  // const maxLength = Math.max(side1Length, side2Length);


  const getVoices = () => {
    return new Promise((resolve) => {
      let voices = speechSynthesis.getVoices();
      if (voices.length) {
        resolve(voices)
        return
      }
      speechSynthesis.onvoiceschanged = () => {
        voices = speechSynthesis.getVoices()
        resolve(voices)
      }
    })
  }


useEffect(() => {
    const loadVoices = async () => {
      const loadedVoices = await getVoices();
      setAllVoices(loadedVoices as SpeechSynthesisVoice[]);  // Cast the loadedVoices to SpeechSynthesisVoice[]
      setVoicesLoaded(true);
    };
    loadVoices();
  }, []);


  const speakTTS = async () => {

    speechSynthesis.cancel();

    console.log("running tts: ",audioCurrentTimeRef.current);

    // if (currentAudioRef.current) {
    //   currentAudioRef.current.pause();
    //   currentAudioRef.current.currentTime = 0;  // Reset the playback position to the beginning
    // }
    if (currentAudioRef.current) {
      console.log("after checking current: ",audioCurrentTimeRef);
      return;
    }

    if (!allVoices || allVoices.length === 0) {
      console.error("No voices available!");
      return; // Exit if no voices are available
    }

    let side1phrase = card.fields.side1p ? card.fields.side1p : card.fields.side1;
    let side2phrase = card.fields.side2p ? card.fields.side2p : card.fields.side2b;
        
    const cardType = card.type ? card.type : "字";
    const cardlang = card.lang ? card.lang : "zh-TW";

    if ( cardType == "部首" ) {
      side2phrase = side2phrase + "字部";
    } else if ( cardType == "vocab" ) {
      side2phrase = side1phrase;
      side1phrase = card.fields.side2b;
    } else {
      side2phrase = side2phrase + "的" + side1phrase;
    }

    // if ( cardType == "天干" ) {
    //   side2phrase = side1phrase;
    // }
    // console.log("cardtype =",cardType);

    if (cardlang.endsWith(".mp3")) {
    const mp3name = !side ? cardlang.split(".")[0] : `${cardlang.split(".")[0]}b`;
    import(`./assets/mp3/${mp3name}.mp3`)
      .then((audioModule) => {
        // Now, audioModule will contain the dynamically imported module
        let audio = new Audio(audioModule.default);
        console.log("new audio ref created");
        currentAudioRef.current = audio;
        audio.play();
      })
      .catch((error) => {
        console.error("Error loading audio file:", error);
      });
    }
    else {
      const message = new SpeechSynthesisUtterance();

      const voiceMap = new Map([
        ['zh-TW', 'Meijia'],
        ['zh-CN', 'Tingting'],
        ['de-DE', 'Anna'],
        ['es-ES', 'Mónica'],
        ['ja-JP', 'Kyoko'],
        ['en-US', 'Samantha'],  // Default voice
      ]);
      const selectedVoiceName = voiceMap.get(cardlang) || 'Samantha';  // Default to 'Samantha' if not found in map

      const voice = allVoices.find((voice) => voice.name === selectedVoiceName) || null;
      if (voice) {
        message.voice = voice;
      } else {
        console.warn(`Voice for ${cardlang} not found, using default voice`);
      }

      message.text = !side ? side2phrase : side1phrase ;
      message.volume = 1; // Volume range = 0 - 1
      message.rate = 0.6; // Speed of the text read , default 1
      message.lang = cardlang; // Language, default 'zh-TW'
      speechSynthesis.speak(message);
    }
  }

  useEffect(() => {
    if (voicesLoaded && speaker) {
      speakTTS();
    }
  }, [voicesLoaded, speaker, side, card.fields.side1]);

  function handleToggleVisibility(event: any) {
    event.stopPropagation(); // Prevent card from being flipped
    dispatch(toggleShowSide1()); // Dispatch action to toggle visibility
  }

  function handleClick() {
    dispatch(setCardSide(!side));
  }

  function handleSpeakerClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    dispatch(setSpeakerState());    

    console.log("currentAudioRef.current",currentAudioRef.current);
    if (currentAudioRef.current) {
      console.log("speaker",speaker);
      if (speaker ) {
        audioCurrentTimeRef.current = currentAudioRef.current.currentTime;
        console.log("Pausing at ",audioCurrentTimeRef.current);
        currentAudioRef.current.pause();        
      } else {
        currentAudioRef.current.currentTime = audioCurrentTimeRef.current;
        console.log("resuming at ",audioCurrentTimeRef.current);
        currentAudioRef.current.play();        
      }
    }
  }

  function handleReplayClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    speakTTS();
  }


  function handleAddRemoveClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    if (isAdded) {
      dispatch(removeFromUserList(card));
    } else {
      dispatch(addToUserList(card));
    }
    setIsAdded(!isAdded);
  }


  const getFontSize = () => {
    if (side1Length > 3) {
      return "small-font";
    } else if (side1Length > 2) {
      return "medium-font";
    } else {
      return "large-font";
    }
  };


  // Font class based on cardType

  const getFontFamilyFrontSide = () => {
    const cardType = card.type || "字"; // Default to "字" if cardType is undefined
    switch (cardType) {
      case "vocab":
        return "serif";
      default:
        return "frontfont";
    }
  };


  const getFontFamilyBackSide = () => {
    const cardType = card.type || "字"; // Default to "字" if cardType is undefined

    switch (cardType) {
      case "注音":
        return "frontfont";
      case "vocab":
        return "serif";
      default:
        return "backfont";
    }
  };
  


  return (
    <div className={`card ${side ? "side" : ""}`} onClick={handleClick}>
      <div className="card-top">
        <button onClick={handleAddRemoveClick} className="add-remove-button">
          {!isAdded ? <StarIcon style={{ width: '36px', height: '36px', color: 'white' }} /> : <StarFillIcon style={{ width: '36px', height: '36px', color: 'yellow' }} />}
        </button>      
        <button onClick={handleSpeakerClick} className="pronounce-button">
          {speaker ? <SpeakerWaveIcon style={{ width: '36px', height: '36px', color: 'white' }} /> : <SpeakerXMarkIcon style={{ width: '36px', height: '36px', color: 'white' }} />}
        </button>
        <button onClick={handleToggleVisibility} className="toggleside1-button">
          {showSide1 ? <EyeIcon style={{ width: '36px', height: '36px', color: 'white' }} /> : <EyeSlashIcon style={{ width: '36px', height: '36px', color: 'white' }} />}
        </button>
      </div>

      {/* Front side */}

      {!showSide1 && !side && 
        <div>
          <button onClick={handleReplayClick} className="replay-button">
            <div className={`eye-icon-transition`}>
              <EyeSlashIcon style={{ width: '60px', height: '60px', color: 'white' }} />
            </div>
          </button>
        </div>}
      {showSide1 && <div className={`front ${getFontSize()}`} style={{ fontFamily: getFontFamilyFrontSide()}}>{card.fields.side1}</div>}

      {/* Back side */}

      <div className={`back ${getFontSize()}`}>
        <div>{card.fields.side2}</div>
        { (card.type != "vocab") && <div className="doublefont">{card.fields.side2}</div> }
        <div className="bottomalign" style={{ fontFamily: getFontFamilyBackSide() }}><hr></hr>{card.fields.side2b}</div>
      </div>
    </div>
  );
}

